import { createRouter, createWebHistory, createWebHashHistory } from 'vue-router'
import Home from '@/views/Home.vue'
import Index from '@/components/index/Index.vue'
import DeviceList from '@/components/list/device-list.vue'
import IncomeList from '@/components/list/income-list.vue'
import AssetsList from '@/components/list/assets-list.vue'
import UserList from '@/components/list/user-list.vue'
import iconList from '@/components/list/icon-list.vue'
import Login from '@/components/login/Login.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
    children: [
      { path: '/index', name: 'index', component: Index },
      { path: 'list/device-list', name: 'device-list', component: DeviceList },
      { path: 'list/assets-list', name: 'assets-list', component: AssetsList },
      { path: 'list/income-list', name: 'income-list', component: IncomeList },
      { path: 'list/icon-list', name: 'icon-list', component: iconList },
      { path: 'list/user-list', name: 'user-list', component: UserList },
    ]
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { top: 0 }
  }
})

export default router
