<template>
  <div>
    <Card :title="node0title" style="margin: 20px">
      <Table border :columns="node0Columns" :data="node0Data"></Table>
    </Card>
    <Card :title="node1title" style="margin: 20px">
      <Table border :columns="node1Columns" :data="node1Data"></Table>
    </Card>
  </div>
</template>

<script>
import { getFormatDuringTime } from "@/utils/util";
export default {
  props: {
    node0Data: {
      type: Array,
      default: [],
    },
    node1Data: {
      type: Array,
      default: [],
    },
  },
  data() {
    return {
      node0Columns: [
        {
          title: "序号",
          type: "index",
          width: 100,
          align: "center",
        },
        {
          title: "状态",
          key: "rtvalue",
          render: (h, params) => {
            return h("div", params.row.rtvalue == 1 ? "开启" : "关闭");
          },
        },
        {
          title: "开关名",
          key: "nodeName",
        },
        {
          title: "控制模式",
          render: (h, params) => {
            return h("div", this.returnModel(params.row));
          },
        },
      ],
      node1Columns: [
        {
          title: "序号",
          type: "index",
          width: 100,
          align: "center",
        },
        {
          title: "状态",
          key: "rtvalue",
          render: (h, params) => {
            return h("div", params.row.rtvalue == 1 ? "有信号" : "无信号");
          },
        },
        {
          title: "开关名",
          key: "nodeName",
        },
        {
          title: "有信号提示",
          key: "openDescribe",
        },
        {
          title: "无信号提示",
          key: "closeDescribe",
        },
      ],
      default: [
        {
          value: "翻转",
        },
        {
          value: "",
        },
        {
          value: "",
        },
        {
          value: "点动开",
        },
        {
          value: "点动关",
        },
        {
          value: "默认动作",
        },
        {
          value: "循环",
        },
        {
          value: "互锁延时",
        },
        {
          value: "互锁点动",
        },
      ],
      node0title: "无",
      node1title: "无",
    };
  },
  mounted() {
    setTimeout(() => {
      this.node0title = `开关(${this.node0Data.length})`;
      this.node1title = `开关量(${this.node1Data.length})`;
    }, 1000);
  },
  methods: {
    returnModel(item) {
      const { defaultAction, fcdlyTime, waitTime } = item;
      return `${
        this.default[defaultAction].value
      }/点动延时: ${getFormatDuringTime(
        fcdlyTime
      )}/等待延时: ${getFormatDuringTime(waitTime)}`;
    },
  },
};
</script>

<style>
</style>