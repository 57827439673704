<template>
  <div>
    <Row :gutter="20" style="min-height: 300px">
      <i-col :lg="24" id="renewalPlay"></i-col>
    </Row>
  </div>
</template>

<script>
import { Chart } from "@antv/g2";

export default {
  name: "renewal--chart",
  props: {
    renewalData: {
      type: Object,
      default: {},
    },
    renewalModel: {
      type: String,
      default: "0",
    },
  },
  watch: {
    renewalModel() {
      setTimeout(() => {
        this.chartDiv.changeData(this.renewalData);
      }, 100);
    },
  },
  mounted() {
    setTimeout(() => {
      this.init();
    }, 1000);
  },
  methods: {
    init() {
      this.chartDiv = new Chart({
        container: "renewalPlay",
        autoFit: true,
        padding: [20, 40, 50, 50],
      });
      this.chartDiv.data(this.renewalData);
      this.chartDiv.scale("deviceNum", {
        alias: "设备数",
      });
      this.chartDiv.line().position("dateStr*deviceNum");
      this.chartDiv.area().position("dateStr*deviceNum");
      this.chartDiv.render();
    },
  },
};
</script>
