<template>
  <div>
    开始时间：
    <DatePicker
      type="date"
      placeholder="选择开始时间"
      style="width: 130px; margin-right: 10px"
      v-model="startTime"
      :model-value="startTime"
    />
    结束时间：
    <DatePicker
      type="date"
      placeholder="选择结束时间"
      style="width: 130px; margin-right: 10px"
      v-model="endTime"
      :model-value="endTime"
    />
    <span v-if="isSelect">
      筛选条件：
      <Select v-model="select" clearable style="width: 100px; margin-right: 10px">
        <Option v-for="item in options" :value="item.value" :key="item.value">{{
          item.label
        }}</Option>
      </Select>
    </span>
    <span v-if="isKeyword">
      关键词：
      <Input
        v-model="keyword"
        placeholder="输入关键词"
        style="width: 200px; margin-right: 10px"
      />
    </span>
    <Button type="primary" @click="confirm">搜索</Button>
  </div>
</template>

<script>
import { dateFormat } from "@/utils/util";
export default {
  props: {
    options: {
      type: Array,
      default: [],
    },
    startTime: {
      type: String,
      default: "",
    },
    endTime: {
      type: String,
      default: "",
    },
    keyword: {
      type: String,
      default: "",
    },
    isSelect: {
      type: Boolean,
      default: true,
    },
    isKeyword: {
      type: Boolean,
      default: true,
    },
    select: ''
  },
  methods: {
    confirm() {
      const { select, startTime, endTime, keyword } = this;
      const params = {
        select,
        startTime: dateFormat(startTime),
        endTime: dateFormat(endTime),
        keyword,
      };
      this.$emit("searchBtn", params);
    },
  },
};
</script>

<style>
</style>