<template>
  <div>
    <PageHeader hidden-breadcrumb>
      <template #title><div class="demo-title">资产日统计</div></template>
    </PageHeader>
    <Card style="margin: 20px">
      <Searchbar
        :startTime="startTime"
        :endTime="endTime"
        :isSelect="false"
        :isKeyword="false"
        @searchBtn="searchBtn"
      ></Searchbar>
      <Table :columns="columns" :data="tabData" style="margin-top: 16px">
      </Table>
      <div class="ivu-mt ivu-text-center">
        <Page
          :total="totalInfoNum"
          :page-size="pageInfoNum"
          show-elevator
          show-total
          show-sizer
          @on-change="changePage"
          @on-page-size-change="changePageSize"
        />
      </div>
    </Card>
  </div>
</template>

<script>
import axios from "axios";
import Searchbar from "@/components/list/components/Searchbar.vue";
import { baseURL, dateFormat } from "@/utils/util";
export default {
  components: {
    Searchbar,
  },
  data() {
    return {
      columns: [
        {
          title: "消费时间",
          key: "dateDay",
        },
        {
          title: "收入金额",
          key: "wxTopUpMoneyStr",
        },
        {
          title: "充值次数",
          key: "wxTopUpNum",
        },
        {
          title: "消费金额",
          key: "consumerMoneyStr",
        },
        {
          title: "消费次数",
          key: "consumerNum",
        },
      ],
      tabData: [],
      currPageNum: 1,
      totalInfoNum: 1,
      pageInfoNum: 10,
      startTime: "2000-09-01 00:00:00",
      endTime: dateFormat(new Date()),
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    searchBtn(e) {
      const { startTime, endTime } = e;
      this.startTime = startTime;
      this.endTime = endTime;
      this.getData();
    },
    changePageSize(e) {
      this.pageInfoNum = e;
      this.getData();
    },
    changePage(e) {
      this.currPageNum = e;
      this.getData();
    },
    async getData() {
      const { pageInfoNum, currPageNum, startTime, endTime } = this;
      let params = {
        pageInfoNum,
        currPageNum,
        startTime,
        endTime,
      };
      const {
        data: {
          info: { objInfo, totalInfoNum },
        },
      } = await axios.get(`${baseURL}getfunddaystatisticstable`, {
        params,
      });
      this.totalInfoNum = totalInfoNum;
      this.tabData = objInfo;
    },
  },
};
</script>
