<template>
  <Menu
    theme="light"
    :open-names="openNames"
    :active-name="activeName"
    accordion
    style="width: 100%"
    @on-select="handleClick"
  >
    <MenuItem name="1" to="/index">
      <Icon
        type="ios-home-outline"
        size="16"
        style="width: 24px; height: 24px; margin-right: 8px"
      />
      首页
    </MenuItem>
    <Submenu v-for="(item, index) in parentMenus" :key="index" :name="item.id">
      <template #title>
        <Icon :type="item.icon" size="16" />{{ item.menuName }}
      </template>
      <MenuItem
        v-for="(child, i) in applyMenusList[item.appcode]"
        :key="i"
        :name="child.id"
      >
        {{ child.menuName }}
      </MenuItem>
    </Submenu>
    <MenuItem name="6" to="/list/user-list">
      <Icon
        type="ios-home-outline"
        size="16"
        style="width: 24px; height: 24px; margin-right: 8px"
      />
      会员管理
    </MenuItem>
  </Menu>
</template>

<script>
import MenuData from "@/utils/MenuData.json";
export default {
  name: "Menus",
  setup() {},
  data() {
    return {
      parentMenus: MenuData.applysList,
      applyMenusList: MenuData.applyMenusList,
      activeName: "",
      openNames: ["1"],
      tabList: this.$store.state.tabList,
    };
  },
  created() {
    var path = this.$route.path;
    if (path != "/") {
      this.toPath(path);
    } else {
      this.defaultMethod();
    }
  },
  watch: {
    "$store.state.tabList"(val) {
      if (val.length > 0) {
        this.tabList = val;
      }
    },
  },
  methods: {
    defaultMethod() {
      this.$router.push({ path: "/index" });
      this.activeName = "1";
    },
    toPath(path) {
      this.openNames = [];
      if (path === "/index") {
        this.activeName = "1";
        return false;
      }
      if (path === "/list/user-list") {
        this.activeName = "6";
        return false;
      }
      this.parentMenus.forEach((menu) => {
        menu.children = this.applyMenusList[menu.appcode];
        menu.children.forEach((child) => {
          if (child.menuLink === path) {
            var index = this.tabList.findIndex((ele) => ele.id === child.id);
            if (index == -1) {
              this.tabList.push(child);
              this.$store.commit("setTabList", this.tabList);
            }
            this.$store.commit("setTabActive", child.id);
            this.$store.commit(
              "setTabIndex",
              this.tabList.findIndex((ele) => ele.id === child.id)
            );
            this.activeName = child.id;
            this.openNames.push(menu.id);
            if (this.$store.state.menuId != child.id) {
              this.$store.commit("setMenuId", child.id);
            }
          }
        });
      });
      this.$store.commit("setMenuList", this.parentMenus);
    },
    handleClick(name) {
      var p = name.substring(0, name.indexOf("-"));
      this.parentMenus.forEach((menu) => {
        if (menu.id === p) {
          this.applyMenusList[menu.appcode].forEach((child) => {
            if (child.id === name) {
              var index = this.tabList.findIndex((ele) => ele.id === child.id);
              if (index == -1) {
                this.tabList.push(child);
                this.$store.commit("setTabList", this.tabList);
              }
              this.activeName = child.id;
              this.$store.commit("setTabActive", child.id);
              this.$store.commit(
                "setTabIndex",
                this.tabList.findIndex((ele) => ele.id === child.id)
              );
              this.$store.commit("setMenuId", child.id);
              this.$router.push({ path: child.menuLink });
            }
          });
        }
        if (name == 1) {
          this.$store.commit("setMenuId", 1);
        }
        if (name == 6) {
          this.$store.commit("setMenuId", 6);
        }
      });
    },
    checkMenu(name) {
      this.parentMenus.forEach((menu) => {
        this.applyMenusList[menu.appcode].forEach((child) => {
          if (child.id === name) {
            var index = this.tabList.findIndex((ele) => ele.id === child.id);
            if (index == -1) {
              this.tabList.push(child);
              this.$store.commit("setTabList", this.tabList);
            }
            this.activeName = child.id;
            this.$store.commit("setTabActive", child.id);
            this.$store.commit(
              "setTabIndex",
              this.tabList.findIndex((ele) => ele.id === child.id)
            );
            this.$store.commit("setMenuId", child.id);
            this.$router.push({ path: child.menuLink });
          }
        });
      });
    },
  },
};
</script>
<style>
.transfer-class-name {
  left: 80px !important;
}
.i-layout-menu-side .ivu-menu-item,
.i-layout-menu-side .ivu-menu-submenu-title {
  height: 52px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>