<template>
  <div>
    <PageHeader hidden-breadcrumb>
      <template #title><div class="demo-title">设备图标</div></template>
    </PageHeader>
    <Card style="margin: 20px">
      <Searchbar
        :startTime="startTime"
        :endTime="endTime"
        :options="options"
        @searchBtn="searchBtn"
      ></Searchbar>
      <Button
        type="primary"
        icon="md-add"
        style="margin-top: 10px"
        @click="openAdd"
        >新增型号</Button
      >
      <Table :columns="columns" :data="tabData" style="margin-top: 16px">
        <template #action="{ row, index }">
          <Button type="error" size="small" @click="remove(index)">删除</Button>
        </template>
      </Table>
      <div class="ivu-mt ivu-text-center">
        <Page
          :total="totalInfoNum"
          :page-size="pageInfoNum"
          show-elevator
          show-total
          show-sizer
          @on-change="changePage"
          @on-page-size-change="changePageSize"
        />
      </div>
    </Card>
    <Modal
      title="新增设备"
      v-model="modal"
      class-name="vertical-center-modal"
      :loading="loading"
      @on-ok="asyncOK"
    >
      <Form
        ref="formInline"
        :model="formInline"
        :rules="ruleInline"
        :label-width="80"
      >
        <FormItem label="设备型号" prop="devType">
          <Input
            type="text"
            v-model="formInline.devType"
            placeholder="请填写设备型号"
          >
          </Input>
        </FormItem>
        <FormItem label="图片上传" prop="url">
          <div v-if="tmpUrl" style="display: inline-block; margin-right: 10px">
            <img :src="tmpUrl" style="width: 58px; height: 60px" />
          </div>
          <Upload
            ref="upload"
            :show-upload-list="false"
            :on-success="handleSuccess"
            :format="['jpg', 'jpeg', 'png']"
            :max-size="2048"
            :before-upload="handleBeforeUpload"
            type="drag"
            action="//jsonplaceholder.typicode.com/posts/"
            style="display: inline-block; width: 58px"
          >
            <div style="width: 58px; height: 58px; line-height: 58px">
              <Icon type="ios-camera" size="20"></Icon>
            </div>
          </Upload>
        </FormItem>
      </Form>
    </Modal>
  </div>
</template>

<script>
import axios from "axios";
import { uploadDevImg } from "@/api/login";
import Searchbar from "@/components/list/components/Searchbar.vue";
import { baseURL, dateFormat } from "@/utils/util";
export default {
  components: {
    Searchbar,
  },
  data() {
    return {
      options: [
        {
          value: "devType",
          label: "型号",
        },
      ],
      tmpType: "",
      tmpUrl: "",
      modal: false,
      loading: true,
      formInline: {
        devType: "",
        url: "",
      },
      ruleInline: {
        devType: [
          {
            required: true,
            message: "设备型号不能为空",
            trigger: "blur",
          },
        ],
        url: [
          {
            required: true,
            message: "必须上传图片",
            trigger: "blur",
          },
        ],
      },
      columns: [
        {
          title: "图标",
          key: "devIcon",
          render: (h, params) => {
            return h(
              "img",
              {
                src: params.row.devIcon,
                style: {
                  width: "48px",
                  height: "48px",
                },
              },
              params.row.devIcon
            );
          },
        },
        {
          title: "型号",
          key: "devType",
        },
        {
          title: "操作",
          slot: "action",
          width: 150,
          align: "center",
        },
      ],
      tabData: [],
      uploadData: new FormData(),
      currPageNum: 1,
      totalInfoNum: 1,
      pageInfoNum: 10,
      startTime: "2000-09-01 00:00:00",
      endTime: dateFormat(new Date()),
      select: "",
      keyword: "",
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    searchBtn(e) {
      const { startTime, endTime, keyword, select } = e;
      this.startTime = startTime;
      this.endTime = endTime;
      this.keyword = keyword;
      this.select = select;
      this.getData();
    },
    show(index) {
      this.formInline.devType = this.tabData[index].devType;
      this.tmpUrl = this.tabData[index].devIcon;
      this.modal = true;
    },
    openAdd() {
      this.modal = true;
    },
    async handleBeforeUpload(file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      setTimeout(() => {
        this.tmpType = file.name.split(".")[1];
        this.tmpUrl = reader.result;
      }, 100);
      this.uploadData.append("file", file);
    },
    async asyncOK() {
      if (this.formInline.devType !== "") {
        if (this.tmpType !== "") {
          this.uploadData.append("devType", this.formInline.devType);
          this.uploadData.append("fileType", this.tmpType);
          const { status } = await uploadDevImg(this.uploadData);
          if (status === 200) {
            this.modal = false;
            this.getData();
            this.uploadData = new FormData();
            this.formInline.devType = "";
            this.tmpType = "";
            this.tmpUrl = "";
          }
        } else {
          this.$Message.info("请上传图片");
          this.loading = false;
          setTimeout(() => {
            this.loading = true;
          }, 10);
        }
      } else {
        this.$Message.info("请填写设备型号");
        this.loading = false;
        setTimeout(() => {
          this.loading = true;
        }, 10);
      }
    },
    async remove(index) {
      const params = {
        id: this.tabData[index].id,
      };
      const { status } = await axios.get(`${baseURL}deldevtypeimageinfo`, {
        params,
      });
      if (status === 200) {
        this.getData();
      }
    },
    changePageSize(e) {
      this.pageInfoNum = e;
      this.getData();
    },
    changePage(e) {
      this.currPageNum = e;
      this.getData();
    },
    async getData() {
      const { pageInfoNum, currPageNum, startTime, endTime, select, keyword } =
        this;
      let params = {
        pageInfoNum,
        currPageNum,
        startTime,
        endTime,
      };
      if (select !== undefined && keyword !== "") {
        params[`${select}`] = keyword;
      }
      const {
        data: {
          info: { objInfo, totalInfoNum },
        },
      } = await axios.get(`${baseURL}getalldevimageinfo`, {
        params,
      });
      this.totalInfoNum = totalInfoNum;
      this.tabData = objInfo;
    },
  },
};
</script>
<style>
.demo-upload-list {
  display: inline-block;
  width: 60px;
  height: 60px;
  text-align: center;
  line-height: 60px;
  border: 1px solid transparent;
  border-radius: 4px;
  overflow: hidden;
  background: #fff;
  position: relative;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  margin-right: 4px;
}
.demo-upload-list img {
  width: 100%;
  height: 100%;
}
.demo-upload-list-cover {
  display: none;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.6);
}
.demo-upload-list:hover .demo-upload-list-cover {
  display: block;
}
.demo-upload-list-cover i {
  color: #fff;
  font-size: 20px;
  cursor: pointer;
  margin: 0 2px;
}
</style>
