import router from '../router'
import store from '../store'
import { Notice, LoadingBar } from 'view-ui-plus'
const allowList = ['login', 'register', 'registerResult'] // no redirect allowList
const loginRoutePath = '/login'
const defaultRoutePath = '/dahsboard/console'

router.beforeEach((to, from, next) => {
  LoadingBar.start() // start progress bar
  /* has token */
  if (store.state.token) {
    if (to.path === loginRoutePath) {
      next({ path: defaultRoutePath })
      LoadingBar.finish()
    } else {
      // set the replace: true so the navigation will not leave a history record
      next()
    }
  } else {
    if (allowList.includes(to.name)) {
      // 在免登录名单，直接进入
      next()
    } else {
      next({ path: loginRoutePath })
      LoadingBar.finish() // if current page is login will not trigger afterEach hook, so manually handle it
    }
  }
})

router.afterEach(() => {
  LoadingBar.finish() // finish progress bar
})
