<template>
  <div v-if="tabList.length>0" class="home-tabs" style="padding: 6px 0;margin: 0 12px 0 12px;">
  <Tabs closable type="card" :model-value="tabActive" @on-tab-remove="handleTabRemove" @on-click="handleClick">
    <TabPane v-for="item in tabList" :key="item.name" :label="item.title" :name="item.id"></TabPane>
    <template #extra>
      <Dropdown v-if="tabList.length>1" @on-click="closeBtn" transfer>
        <Button icon="ios-arrow-down"></Button>
        <template #list>
          <DropdownMenu>
              <DropdownItem name="1"><Icon type="md-arrow-back" />关闭左侧</DropdownItem>
              <DropdownItem name="2"><Icon type="md-arrow-forward" />关闭右侧</DropdownItem>
              <DropdownItem name="3"><Icon type="md-close" />关闭其它</DropdownItem>
              <DropdownItem name="4"><Icon type="ios-close-circle" />全部关闭</DropdownItem>
          </DropdownMenu>
        </template>
      </Dropdown>
    </template>
  </Tabs>
  </div>
</template>

<script>
export default {
  name: 'Menus',
  props: {
      isCollapsed: {
        type: Boolean,
        default: true
      },
  },
  setup() {

  },
  data () {
      return {
          tabList:this.$store.state.tabList,
          menuList:this.$store.state.menuList,
          tabActive:this.$store.state.tabActive,
          tabIndex:this.$store.state.tabIndex,
      }
  },
  created(){
  },
  watch:{
    '$store.state.tabList'(val){
      if(val.length>0){
        this.tabList = val
      }
    },
    '$store.state.tabActive'(val){
      if(val){
        this.tabActive = val
      }
    },
    '$store.state.tabIndex'(val){
      if(val){
        this.tabIndex = val
      }
    }
  },
  methods: {
    defaultMethod(){
      //默认打开第一个菜单
      this.tabList = []
      var menu = this.menuList[0].children[0];
      var index = this.tabList.findIndex(ele => ele.id === menu.id)
      if(index==-1){
        this.tabList.push(menu)
      }
      this.$router.push({path:menu.menuLink})
      this.$store.commit('setMenuId', menu.id)
      this.$store.commit('setTabList', this.tabList)
      this.$store.commit('setTabActive', menu.id)
      this.$store.commit('setTabIndex', this.tabList.findIndex(ele => ele.id === menu.id))
    },
    handleTabRemove (name) {
      var defaultIndex = 0
      this.tabList.forEach((element,index) => {
        if(element.id===name){
          defaultIndex = index
          this.tabList.splice(index,1)
        }
      });
      if(defaultIndex>0){
        defaultIndex--
      }
      if(defaultIndex==0 && this.tabList.length==0){
        this.defaultMethod()
      }else{
        this.handleClick(this.tabList[defaultIndex].id)
        this.$store.commit('setMenuId', this.tabList[defaultIndex].id)
        this.$store.commit('setTabList', this.tabList)
        this.$store.commit('setTabActive', this.tabList[defaultIndex].id)
        this.$store.commit('setTabIndex', this.tabList.findIndex(ele => ele.id === this.tabList[defaultIndex].id))
      }
    },
    handleClick(name) {
      this.tabList.forEach((element,index) => {
        if(element.id===name){
          this.$store.commit('setMenuId', element.id)
          this.$store.commit('setTabActive', name)
          this.$store.commit('setTabIndex', index)
          this.$router.push({path:element.menuLink})
        }
      });
    },
    closeBtn(name){
      var list = [];
      if(name==='1'){
        // 关闭左侧
        list = this.tabList.filter((i,index)=>{
          return index>=this.tabIndex;
        })
      }else if(name==='2'){
        // 关闭右侧
        list = this.tabList.filter((i,index)=>{
          return index<=this.tabIndex;
        })
      }else if(name==='3'){
        // 关闭其它
        list = this.tabList.filter((i,index)=>{
          return index==this.tabIndex;
        })
      } 
      if(name==='4'){
        //关闭全部，默认跳转第一个菜单
        this.defaultMethod();
      }else{
        this.tabList = list;
        this.$store.commit('setTabList', this.tabList)
        this.refreshTab();
      }
    },
    refreshTab(){
      this.tabList.forEach((element,index) => {
        if(element.id===this.tabActive){
          this.tabIndex = index
          this.$store.commit('setTabIndex', index)
        }
      });
    }
  }
}
</script>
<style  scoped>
.home-tabs >>> .ivu-tabs-bar {
    border-bottom: none; 
    margin-bottom: 0px;
}
.home-tabs >>> .ivu-tabs.ivu-tabs-card > .ivu-tabs-bar .ivu-tabs-tab{
  border: none;
  background-color: #fff;
}
</style>