<template>
<div class="ivu-layout">
    <Layout :style="{minHeight: clientHeight+'px'}">
        <Sider class="ivu-layout-has-sider i-layout-sider" ref="side1" hide-trigger collapsible :collapsed-width="78"  :width="230">
            <SiderLayout />
        </Sider>
        <Layout>
            <Header :style="{padding: 0}" class="layout-header-bar">
                <HeaderLayout  v-on:collapsed-sider="collapsedSider"/>
            </Header>
            <Content>
                <!-- <Tabs/> -->
                <router-view></router-view>
            </Content>
            <Footer>
                <FooterLayout/>
            </Footer>
        </Layout>
    </Layout>
</div>
</template>
<script>
import HeaderLayout from './HeaderLayout.vue'
import FooterLayout from './FooterLayout.vue'
import Tabs from '@/components/permission/Tabs.vue'
import SiderLayout from './SiderLayout.vue'
export default {
  name: 'MainLayout',
  components: {
    HeaderLayout,FooterLayout,Tabs,SiderLayout
  },
  data () {
      return {
        clientHeight: 550,
      }
  },
  watch:{
    
  },
  mounted() {
    this.clientHeight = `${document.documentElement.clientHeight}`       //document.body.clientWidth;
    window.onresize = function temp() {
      this.clientHeight = `${document.documentElement.clientHeight}`;
    };
  },
  methods: {
      collapsedSider () {
          this.$refs.side1.toggleCollapse();
      }
  }
}
</script>
<style scoped>
    .layout{
        border: 1px solid #d7dde4;
        background: #f5f7f9;
        position: relative;
        border-radius: 4px;
        overflow: hidden;
    }
    .layout .ivu-menu{
        z-index: 0
    }
    .layout-header-bar{
        background: #fff;
        box-shadow: 0 1px 1px rgba(0,0,0,.1);
    }
    .layout-logo-left{
        width: 90%;
        height: 30px;
        background: #5b6270;
        border-radius: 3px;
        margin: 15px auto;
    }
    .menu-icon{
        transition: all .3s;
    }
    .rotate-icon{
        transform: rotate(-90deg);
    }
    .menu-item span{
        display: inline-block;
        overflow: hidden;
        width: 69px;
        text-overflow: ellipsis;
        white-space: nowrap;
        vertical-align: bottom;
        transition: width .2s ease .2s;
    }
    .menu-item i{
        transform: translateX(0px);
        transition: font-size .2s ease, transform .2s ease;
        vertical-align: middle;
        font-size: 16px;
    }
    .collapsed-menu span{
        width: 0px;
        transition: width .2s ease;
    }
    .collapsed-menu i{
        transform: translateX(5px);
        transition: font-size .2s ease .2s, transform .2s ease .2s;
        vertical-align: middle;
        font-size: 22px;
    }
</style>