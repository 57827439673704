<template>
  <div>
    <Row :gutter="20" style="min-height: 300px">
      <i-col :lg="24" id="wxPlay"></i-col>
    </Row>
  </div>
</template>

<script>
import { Chart } from "@antv/g2";

export default {
  name: "wx-chart",
  props: {
    wxData: {
      type: Object,
      default: {},
    },
    wxModel: {
      type: String,
      default: "0",
    },
  },
  watch: {
    wxModel() {
      setTimeout(() => {
        this.chartDiv.changeData(this.wxData);
      }, 100);
    },
  },
  mounted() {
    setTimeout(() => {
      this.init();
    }, 1000);
  },
  methods: {
    init() {
      this.chartDiv = new Chart({
        container: "wxPlay",
        autoFit: true,
        padding: [20, 40, 50, 50],
      });
      this.chartDiv.data(this.wxData);
      this.chartDiv.scale("dTotalAmount", {
        alias: "充值额",
      });
      this.chartDiv.line().position("dateStr*dTotalAmount");
      this.chartDiv.area().position("dateStr*dTotalAmount");
      this.chartDiv.render();
    },
  },
};
</script>
