//引入vuex
import MenuData from '@/utils/MenuData.json'
//根据应用类型和菜单ID 得到导航菜单
const getMenuBreadcrumb = function (applysList, applyMenusList, menuApply, menuId) {
  let res = [], menusList = [], parentList = [], menu = {}, flag = false, base = {}
  if (menuApply == '') { //个人办公
    menusList = applyMenusList
    parentList = applysList
    base = {
      menuName: '首页',
      menuLink: '/index',
      menuIconSmall: 'fa fa-home'
    }
  }
  res.push(base)
  if (menuId === 6) {
    res.push({
      menuName: '会员管理'
    })
  }
  for (var key in menusList) {
    menu = getObj(menusList[key].children, 'id', menuId)
    if (menu && menu.id) {
      let parent = getObj(parentList, menuApply == '' ? 'id' : 'appcode', key)
      parent.thumbnail = menusList[key].menuIconSmall;
      parent.name = menusList[key].menuName;
      parent.child = menusList[key].children;
      res.push(parent)
      res.push(menu)
    }
  }
  return res
}

//根据应用类型和菜单ID 获取菜单对象
const getMenu = function (menuApply, menuId) {
  let menusList = [], menu = {}
  if (menuApply == '') {
    menusList = MenuData.menusList
  } else if (menuApply == 'my_apply') {
    menusList = MenuData.applyMenusList
  }
  for (var key in menusList) {
    if (!menu.id) {
      menu = getObj(menusList[key], 'id', menuId)
    }
  }
  return menu
}

function getObj(list, key, value) {
  let obj = {}
  list.forEach(function (item) {
    if (item[key] == value) {
      obj = item
    }
  })
  return obj;
}

export { getMenuBreadcrumb, getMenu };
