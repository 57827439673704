import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate'

export default createStore({
  state: {
    menuId:'', // 选中菜单
    tabList:[], 
    token:'',
    menuList:[],
    tabActive:'',
    tabIndex:0,
  },
  getters: {

  },
  mutations: {
    setMenuId(state,menuId){
      state.menuId = menuId
    },
    setTabList(state,tabList){
      state.tabList = tabList
    },
    setToken(state,token){
      state.token = token
    },
    setMenuList(state,menuList){
      state.menuList = menuList
    },
    setTabActive(state,tabActive){
      state.tabActive = tabActive
    },
    setTabIndex(state,tabIndex){
      state.tabIndex = tabIndex
    }
  },
  actions: {

  },
  modules: {

  },
  plugins: [createPersistedState()]
})
