<template>
  <div>
    <Row :gutter="20" style="min-height: 300px">
      <i-col :lg="24" id="user"></i-col>
    </Row>
  </div>
</template>

<script>
import { getUsersNum } from "@/api/login";
import { Chart } from "@antv/g2";
export default {
  name: "user-chart",
  setup() {},
  created() {},
  mounted() {
    setTimeout(() => {
      this.init();
    }, 10);
  },
  methods: {
    async init() {
      const { info } = await getUsersNum();
      const data = [
        { type: "会员总数", value: info.userTotalNum },
        { type: "有设备会员", value: info.bindDevUserNum },
        { type: "付费会员", value: info.payUserNum },
      ];
      const chart = new Chart({
        container: "user",
        autoFit: true,
        height: 300,
      });
      chart.data(data);
      chart.scale("value", {
        alias: "人数",
        nice: true,
      });
      chart.axis("type", {
        tickLine: null,
      });

      chart.tooltip({
        showMarkers: false,
      });
      chart.interaction("active-region");

      chart
        .interval()
        .position("type*value")
        .style("type", (val) => {});

      data.forEach((item) => {
        chart.annotation().text({
          position: [item.type, item.value],
          content: item.value,
          style: {
            textAlign: "center",
          },
          offsetY: -30,
        });
      });

      chart.render();
    },
  },
};
</script>
