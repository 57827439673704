<template>
  <div>
    <PageHeader hidden-breadcrumb>
      <template #title><div class="demo-title">收入流水</div></template>
    </PageHeader>
    <Card style="margin: 20px">
      <Searchbar
        :startTime="startTime"
        :endTime="endTime"
        :options="options"
        @searchBtn="searchBtn"
      ></Searchbar>
      <Table :columns="columns" :data="tabData" style="margin-top: 16px">
      </Table>
      <div class="ivu-mt ivu-text-center">
        <Page
          :total="totalInfoNum"
          :page-size="pageInfoNum"
          show-elevator
          show-total
          show-sizer
          @on-change="changePage"
          @on-page-size-change="changePageSize"
        />
      </div>
    </Card>
  </div>
</template>

<script>
import axios from "axios";
import Searchbar from "@/components/list/components/Searchbar.vue";
import { baseURL, dateFormat } from "@/utils/util";
export default {
  components: {
    Searchbar,
  },
  data() {
    return {
      options: [
        {
          value: "userName",
          label: "用户名",
        },
        {
          value: "phone",
          label: "手机号",
        },
      ],
      columns: [
        {
          title: "会员",
          key: "userName",
        },
        {
          title: "手机号",
          key: "phoneNum",
        },
        {
          title: "类型",
          key: "orderTypeStr",
        },
        {
          title: "余额",
          key: "balance",
        },
        {
          title: "充值时间",
          key: "createDateStr",
        },
      ],
      tabData: [],
      currPageNum: 1,
      totalInfoNum: 1,
      pageInfoNum: 10,
      startTime: "2000-09-01 00:00:00",
      endTime: dateFormat(new Date()),
      select: "",
      keyword: "",
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    searchBtn(e) {
      const { startTime, endTime, keyword, select } = e;
      this.startTime = startTime;
      this.endTime = endTime;
      this.keyword = keyword;
      this.select = select;
      this.getData();
    },
    changePageSize(e) {
      this.pageInfoNum = e;
      this.getData();
    },
    changePage(e) {
      this.currPageNum = e;
      this.getData();
    },
    async getData() {
      const { pageInfoNum, currPageNum, startTime, endTime, select, keyword } =
        this;
      let params = {
        pageInfoNum,
        currPageNum,
        startTime,
        endTime,
      };
      if (select !== undefined && keyword !== "") {
        params[`${select}`] = keyword;
      }
      const {
        data: {
          info: { objInfo, totalInfoNum },
        },
      } = await axios.get(`${baseURL}getwxtopuplist`, {
        params,
      });
      this.totalInfoNum = totalInfoNum;
      this.tabData = objInfo;
    },
  },
};
</script>
