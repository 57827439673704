<template>
  <div>
    <Card title="喇叭" style="margin: 20px">
      <Table border :columns="hornColumns" :data="hornData"> </Table>
    </Card>
    <Card title="电源/电池" style="margin: 20px">
      <Table border :columns="electricColumns" :data="electricData"> </Table>
    </Card>
    <Card title="播报内容" style="margin: 20px">
      <Table border :columns="broadcastColumns" :data="broadcastData"> </Table>
    </Card>
    <Card :title="switchTitle" style="margin: 20px">
      <Table border :columns="switchColumns" :data="toPlaydata"> </Table>
    </Card>
  </div>
</template>

<script>
import axios from "axios";
import { baseURL, getFormatDuringTime } from "@/utils/util";
import { getvoicesetinfo, getalarmsettinginfo } from "@/api/login";
export default {
  props: {
    devSn: {
      type: String,
      default: "0",
    },
    battery: {
      type: Object,
      default: {},
    },
    hornData: {
      type: Array,
      default: [],
    },
  },
  data() {
    return {
      hornColumns: [
        {
          title: "状态",
          key: "rtvalue",
          render: (h, params) => {
            return h("div", params.row.rtvalue === 1 ? "开" : "关");
          },
        },
        {
          title: "名称",
          key: "nodeName",
        },
        {
          title: "手动控制模式",
          render: (h) => {
            return h("div", this.returnControl());
          },
        },
        {
          title: "报警控制模式",
          render: (h) => {
            return h("div", this.returnTime());
          },
        },
      ],
      electricColumns: [
        {
          title: "电池供电",
          key: "isPowerSupply",
        },
        {
          title: "电池电量",
          key: "batteryPower",
        },
        {
          title: "电源状态",
          key: "batteryState",
        },
        {
          title: "三相电状态",
          key: "tpsState",
        },
      ],
      broadcastColumns: [
        {
          title: "类型",
          key: "type",
        },
        {
          title: "内容",
          key: "value",
        },
        {
          title: "报警次数",
          render: (h) => {
            return h("div", this.returnNum());
          },
        },
        {
          title: "重复间隔",
          render: (h) => {
            return h("div", this.returnInterval());
          },
        },
      ],
      switchColumns: [
        {
          title: "序号",
          type: "index",
          width: 100,
          align: "center",
        },
        {
          title: "开关量名称",
          key: "defenceName",
        },
        {
          title: "有信号提示",
          key: "hasSignal",
          render: (h, params) => {
            return h("div", params.row.hasSignal ? "有信号" : "无信号");
          },
        },
        {
          title: "无信号提示",
          key: "notHasSignal",
          render: (h, params) => {
            return h("div", params.row.notHasSignal ? "有信号" : "无信号");
          },
        },
      ],
      electricData: [],
      broadcastData: [],
      toPlaydata: [],
      info: {},
      batteryStateList: [
        {
          value: "正常",
        },
        {
          value: "停电",
        },
        {
          value: "来电",
        },
      ],
      tps: [
        {
          value: "正常",
        },
        {
          value: "缺相",
        },
        {
          value: "错相",
        },
        {
          value: "恢复",
        },
      ],
      switchTitle: "无",
    };
  },
  mounted() {
    setTimeout(() => {
      this.getData();
      this.getToPlaydata();
      const { isPowerSupply, batteryPower, batteryState, tpsState } =
        this.battery;
      this.electricData.push({
        isPowerSupply,
        batteryPower,
        batteryState: this.batteryStateList[batteryState].value,
        tpsState: this.tps[tpsState].value,
      });
    }, 100);
  },
  methods: {
    returnControl() {
      return this.hornData[0].defaultAction === 0 ? "自动鸣笛" : "点动鸣笛";
    },
    returnTime() {
      return `延时时间:${this.hornData[0].waitTime}`;
    },
    async getToPlaydata() {
      const {
        info: { defenceAlarmInfos },
      } = await getalarmsettinginfo({ uid: this.devSn });
      this.toPlaydata = defenceAlarmInfos;
      this.switchTitle = `开关量(${this.toPlaydata.length})`;
    },
    returnNum() {
      return this.info.ttsLoopNum;
    },
    returnInterval() {
      return getFormatDuringTime(this.info.ttsInterval);
    },
    async getData() {
      const { info } = await getvoicesetinfo({ uid: this.devSn });
      const {
        ttsMsg,
        powerDownMsg,
        powerUpMsg,
        defaultPhaseMsg,
        errorPhaseMsg,
        recoverMsg,
        daVoices,
      } = info;
      let tmpData = [
        {
          type: "实时",
          value: ttsMsg,
        },
        {
          type: "停电",
          value: powerDownMsg,
        },
        {
          type: "来电",
          value: powerUpMsg,
        },
        {
          type: "缺相",
          value: defaultPhaseMsg,
        },
        {
          type: "错相",
          value: errorPhaseMsg,
        },
        {
          type: "恢复",
          value: recoverMsg,
        },
      ];
      daVoices.map((item) => {
        tmpData.push(
          {
            type: `${item.defenceAreaName}有信号`,
            value: item.hasSignalVoice,
          },
          {
            type: `${item.defenceAreaName}无信号`,
            value: item.notHasSignalVoice,
          }
        );
      });
      this.broadcastData = tmpData;
      this.info = info;
    },
  },
};
</script>

<style>
</style>