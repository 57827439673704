<template>
  <div>
    <Grid :col="2" padding="10px">
      <GridItem>报警方式</GridItem>
      <GridItem>开启</GridItem>
      <GridItem>微信公众号报警</GridItem>
      <GridItem>
        {{ returnSelect(info.weChatAcBits) || "无设置" }}
      </GridItem>
      <GridItem>电话短信报警</GridItem>
      <GridItem>
        {{ returnSelect(info.phoneAcBits) || "无设置" }}
      </GridItem>
      <GridItem>设备喇叭报警</GridItem>
      <GridItem>
        {{ returnSelect(info.trumpetAcBits) || "无设置" }}
      </GridItem>
      <GridItem>设备语音报警</GridItem>
      <GridItem>
        {{ returnSelect(info.devAcBits) || "无设置" }}
      </GridItem>
      <GridItem>APP语音报警</GridItem>
      <GridItem>
        {{ returnSelect(info.appAcBits) || "无设置" }}
      </GridItem>
    </Grid>
    <Table
      border
      style="margin-top: 20px"
      :columns="phonecolumns"
      :data="phoneData"
    ></Table>
    <Table
      border
      style="margin-top: 20px"
      :columns="toPlaycolumns"
      :data="toPlaydata"
    ></Table>
  </div>
</template>

<script>
import { getFormatDuringTime } from "@/utils/util";
import { getalarmsettinginfo } from "@/api/login";
export default {
  props: {
    devSn: {
      type: String,
      default: "0",
    },
  },
  data() {
    return {
      default: [
        {
          value: "停电",
        },
        {
          value: "来电",
        },
        {
          value: "缺相/错相",
        },
        {
          value: "防区",
        },
      ],
      phonecolumns: [
        {
          title: "报警电话",
          key: "phoneNum",
        },
        {
          title: "电话报警",
          key: "phoneAlarm",
          render: (h, params) => {
            return h("div", params.row.phoneAlarm ? "是" : "否");
          },
        },
        {
          title: "短信报警",
          key: "smsAlarm",
          render: (h, params) => {
            return h("div", params.row.smsAlarm ? "是" : "否");
          },
        },
        {
          title: "报警次数",
          render: (h) => {
            return h("div", this.returnDevAn());
          },
        },
        {
          title: "重复间隔",
          render: (h) => {
            return h("div", this.returnDevAi());
          },
        },
      ],
      toPlaycolumns: [
        {
          title: "防区(撤防)",
          key: "defenceName",
        },
        {
          title: "有信号报警",
          key: "hasSignal",
          render: (h, params) => {
            return h("div", params.row.hasSignal ? "是" : "否");
          },
        },
        {
          title: "无信号报警",
          key: "notHasSignal",
          render: (h, params) => {
            return h("div", params.row.notHasSignal ? "是" : "否");
          },
        },
      ],
      phoneData: [],
      toPlaydata: [],
      info: {},
    };
  },
  mounted() {
    setTimeout(() => {
      this.getData();
    });
  },
  methods: {
    returnSelect(list) {
      if (list !== undefined) {
        let tmp = "";
        list.map((item, index) => {
          if (item) {
            tmp += `${this.default[index].value}、`;
          }
        });
        tmp = tmp.substr(0, tmp.length - 1);
        return tmp;
      }
    },
    returnDevAi() {
      return getFormatDuringTime(this.info.devAi);
    },
    returnDevAn() {
      return this.info.devAn;
    },
    async getData() {
      const { info } = await getalarmsettinginfo({ uid: this.devSn });
      this.info = info;
      this.phoneData = info.alarmPhoneInfos.filter(
        (item) => item.phoneNum !== ""
      );
      this.toPlaydata = info.defenceAlarmInfos;
    },
  },
};
</script>

<style>
</style>