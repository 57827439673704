<template>
  <div>
    <PageHeader hidden-breadcrumb>
      <template #title><div class="demo-title">设备信息</div></template>
    </PageHeader>
    <Card style="margin: 20px" v-if="showList">
      <Searchbar
        :startTime="startTime"
        :endTime="endTime"
        :options="options"
        @searchBtn="searchBtn"
      ></Searchbar>
      <Table :columns="columns" :data="tabData" style="margin-top: 16px">
        <template #action="{ row, index }">
          <Button type="primary" size="small" @click="showInfo(index)"
            >详情</Button
          >
        </template>
      </Table>
      <div class="ivu-mt ivu-text-center">
        <Page
          :total="totalInfoNum"
          :page-size="pageInfoNum"
          show-elevator
          show-total
          show-sizer
            :model-value="currPageNum"
          @on-change="changePage"
          @on-page-size-change="changePageSize"
        />
      </div>
    </Card>
    <Card style="margin: 20px" v-else>
      <DeviceInfo :devSn="devSn" @changeBack="isBack"></DeviceInfo>
    </Card>
  </div>
</template>

<script>
import axios from "axios";
import DeviceInfo from "@/components/list/components/DeviceInfo.vue";
import Searchbar from "@/components/list/components/Searchbar.vue";
import { baseURL, dateFormat } from "@/utils/util";
export default {
  components: {
    DeviceInfo,
    Searchbar,
  },
  data() {
    return {
      options: [
        {
          value: "devType",
          label: "设备型号",
        },
        {
          value: "devUid",
          label: "序列号",
        },
        {
          value: "userName",
          label: "用户名",
        },
        {
          value: "isOnline",
          label: "在线状态",
        },
      ],
      columns: [
        {
          title: "设备型号",
          key: "devTypeName",
          width: 200,
        },
        {
          title: "序列号",
          key: "devSn",
          width: 250,
        },
        {
          title: "所属会员",
          key: "userNames",
        },
        {
          title: "入网类型",
          key: "netType",
          render: (h, params) => {
            return h("div", this.returnType(params.row.netType));
          },
        },
        {
          title: "信号强度",
          key: "sign",
        },
        {
          title: "在线状态",
          key: "isOnline",
          render: (h, params) => {
            return h("div", params.row.isOnline ? "在线" : "离线");
          },
        },

        {
          title: "年费",
          key: "devAnnualFee",
          render: (h, params) => {
            return h("div", [params.row.devAnnualFee / 100]);
          },
        },
        {
          title: "短信费率",
          key: "smsRate",
        },
        {
          title: "电话费率",
          key: "phoneRate",
        },
        {
          title: "最后在线时间",
          key: "lastOnlineTimeStr",
          width: 200,
        },
        {
          title: "操作",
          slot: "action",
          width: 150,
          align: "center",
        },
      ],
      tabData: [],
      showList: true,
      info: {},
      devSn: "",
      currPageNum: 1,
      totalInfoNum: 1,
      pageInfoNum: 10,
      startTime: "2000-09-01 00:00:00",
      endTime: dateFormat(new Date()),
      select: "",
      keyword: "",
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    searchBtn(e) {
      const { startTime, endTime, keyword, select } = e;
      this.startTime = startTime;
      this.endTime = endTime;
      this.keyword = keyword;
      this.select = select;
      this.getData();
    },
    returnType(type) {
      if (type === 0) {
        return "有线以太网";
      }
      if (type === 1) {
        return "GPRS 2G";
      }
      if (type === 2) {
        return "GPRS 4G";
      }
      if (type === 3) {
        return "WIFI";
      }
      if (type === 4) {
        return "NBIOT";
      }
      if (type === "F") {
        return "未知类型";
      }
    },
    isBack() {
      this.showList = true;
    },
    showInfo(index) {
      this.devSn = this.tabData[index].devSn;
      this.showList = false;
    },
    changePageSize(e) {
      this.pageInfoNum = e;
      this.getData();
    },
    changePage(e) {
      this.currPageNum = e;
      this.getData();
    },
    async getData() {
      const { pageInfoNum, currPageNum, startTime, endTime, select, keyword } =
        this;
      let params = {
        pageInfoNum,
        currPageNum,
        startTime,
        endTime,
      };
      if (select !== undefined && keyword !== "") {
        params[`${select}`] = keyword;
        if (select === "isOnline") {
          params[`${select}`] = keyword === "在线" ? 1 : 0;
        }
      }
      const {
        data: {
          info: { objInfo, totalInfoNum },
        },
      } = await axios.get(`${baseURL}getsystemalldevices`, {
        params,
      });
      this.totalInfoNum = totalInfoNum;
      this.tabData = objInfo;
    },
  },
};
</script>
