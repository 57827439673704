<template>
  <div>
    <Card title="设备分享列表" style="margin: 20px">
      <Table border :columns="deviceColumns" :data="deviceData"></Table>
    </Card>
    <Card title="分享记录列表" style="margin: 20px">
      <Table border :columns="shareColumns" :data="shareData"></Table>
    </Card>
  </div>
</template>

<script>
import axios from "axios";
import { baseURL } from "@/utils/util";
import { readshareinfobydevuid } from "@/api/login";
export default {
  props: {
    devId: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      deviceColumns: [
        {
          title: "序号",
          type: "selection",
          width: 60,
          align: "center",
        },
        {
          title: "昵称",
          key: "userName",
        },
        {
          title: "头像",
          key: "headImg",
          render: (h, params) => {
            return h(
              "img",
              {
                src: params.row.headImg,
                style: {
                  width: "48px",
                  height: "48px",
                },
              },
              params.row.headImg
            );
          },
        },
        {
          title: "权限",
          render: (h, params) => {
            return h("div", this.returnPermissions(params.row));
          },
        },
        {
          title: "分享日期",
          key: "createDate",
        },
        {
          title: "起始日期",
          key: "startTime",
        },
        {
          title: "截止日期",
          key: "endTime",
        },
        {
          title: "长期",
          key: "longTimeFlag",
        },
      ],
      shareColumns: [
        {
          title: "序号",
          type: "selection",
          width: 60,
          align: "center",
        },
        {
          title: "ID",
          key: "id",
        },
        {
          title: "创建用户",
          key: "sourceUserName",
        },
        {
          title: "创建时间",
          key: "createDate",
        },
        {
          title: "接受次数",
          key: "getCount",
        },
        {
          title: "权限",
          render: (h, params) => {
            return h("div", this.returnPermissions(params.row));
          },
        },
        {
          title: "分享次数",
          key: "shareNum",
        },
        {
          title: "二维码有效期",
          key: "qrCodeEndDateStr",
        },
        {
          title: "分享有效期",
          key: "endTime",
        },
      ],
      deviceData: [],
      shareData: [],
    };
  },
  mounted() {
    setTimeout(() => {
      if (this.devId !== 0) {
        this.getDeviceShare();
        this.getShareDevice();
      }
    }, 100);
  },
  methods: {
    async getDeviceShare() {
      const { info } = await readshareinfobydevuid({ devId: this.devId });
      this.deviceData = info || [];
    },
    async getShareDevice() {
      const params = {
        devId: this.devId,
      };
      const {
        data: { info },
      } = await axios.get(`${baseURL}mbgetsharedevicelistbydevid`, {
        params,
      });
      this.shareData = info || [];
    },
    returnPermissions(item) {
      const { ctrlFlag, deleteFalg, editFlag } = item;
      let res = "";
      if (editFlag) {
        res += "编辑、";
      }
      if (deleteFalg) {
        res += "删除、";
      }
      if (ctrlFlag) {
        res += "控制、";
      }
      res = res.substr(0, res.length - 1);
      return res;
    },
  },
};
</script>

<style>
</style>