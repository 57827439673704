import request from '@/utils/request'

const userApi = {
  Login: '/userList',
  Logout: '/logout',
  ForgePassword: '/auth/forge-password',
  Register: '/auth/register',
  twoStepCode: '/auth/2step-code',
  SendSms: '/account/sms',
  SendSmsErr: '/account/sms_err',
  // get my info
  UserInfo: '/getUsers',
  UserMenu: '/user/nav'
}

// 获取播报信息
export function getvoicesetinfo(parameter) {
  return request({
    url: 'getvoicesetinfo',
    method: 'post',
    data: parameter
  })
}

// 获取报警设置信息
export function getalarmsettinginfo(parameter) {
  return request({
    url: 'getalarmsettinginfo',
    method: 'post',
    data: parameter
  })
}


// 修改有效期、年费、短信费率、电话费率
export function mbmodifydeviceinfo(parameter) {
  return request({
    url: 'mbmodifydeviceinfo',
    method: 'post',
    data: parameter
  })
}

// 根据设备devsn获取设备信息
export function getdeviceinfobydevsn(parameter) {
  return request({
    url: 'getdeviceinfobydevsn',
    method: 'post',
    data: parameter
  })
}

// 根据设备ID获取该设备的分享列表
export function readshareinfobydevuid(parameter) {
  return request({
    url: 'readshareinfobydevuid',
    method: 'post',
    data: parameter
  })
}

// 新增型号和上传图片
export function uploadDevImg(parameter) {
  return request({
    url: 'uploaddevtypeimage',
    method: 'post',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    data: parameter
  })
}

// 获取设备信息
export function getDevice() {
  return request({
    url: 'getsystemalldevices',
    method: 'get',

  })
}

// 获取设备统计信息
export function getDeviceNum() {
  return request({
    url: 'getdevicestatisticsinfo',
    method: 'get',
  })
}

// 获取会员统计信息
export function getUsersNum() {
  return request({
    url: 'getuserstatistics',
    method: 'get',
  })
}

// 获取所有设备统计信息
export function getAllDevice() {
  return request({
    url: 'getdevicetypeinfo',
    method: 'get',
  })
}

// 获取短信和电话的统计信息
export function getCallSmsNum() {
  return request({
    url: 'getsmsphonestatistics',
    method: 'get',
  })
}

// 获取设备使用信号统计信息
export function getSignal() {
  return request({
    url: 'getdeviceproportion',
    method: 'get',
  })
}

// 获取用户转化信息
export function getConversion() {
  return request({
    url: 'getuserconversioninfo',
    method: 'get',
  })
}

// 获取验证码
export function getCode() {
  return request({
    url: 'user',
    method: 'get',
  })
}


/**
 * login func
 * parameter: {
 *     username: '',
 *     password: '',
 *     remember_me: true,
 *     captcha: '12345'
 * }
 * @param parameter
 * @returns {*}
 */
export function login(parameter) {
  return request({
    url: 'toLogin',
    method: 'post',
    data: parameter
  })
}

export function getSmsCaptcha(parameter) {
  return request({
    url: userApi.SendSms,
    method: 'post',
    data: parameter
  })
}

export function getInfo() {
  return request({
    url: userApi.UserInfo,
    method: 'get',
  })
}



export function getCurrentUserNav() {
  return request({
    url: userApi.UserMenu,
    method: 'get'
  })
}



export function logout() {
  return request({
    url: userApi.Logout,
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}

/**
 * get user 2step code open?
 * @param parameter {*}
 */
export function get2step(parameter) {
  return request({
    url: userApi.twoStepCode,
    method: 'post',
    data: parameter
  })
}
