<template>
  <MainLayout />
</template>

<script>
import MainLayout from "@/views/MainLayout";
export default {
  name: "HomeView",
  components: { MainLayout },
};
</script>
