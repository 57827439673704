<template>
<div style="text-align:center">
  2022- &copy; niren
</div>
</template>

<script>

export default {
  name: 'FooterLayout',
  components: {
    
  }
}
</script>
