<template>
  <div>
    <Tabs value="name1">
      <TabPane label="设备信息" name="name1">
        <Grid :col="2" padding="10px">
          <GridItem style="height: 76px; line-height: 56px">图标</GridItem>
          <GridItem
            ><img style="width: 50px; height: 50px" :src="info.devIcon"
          /></GridItem>
          <GridItem>名称</GridItem>
          <GridItem>{{ info.devName || "无" }}</GridItem>
          <GridItem>型号</GridItem>
          <GridItem>{{ info.devTypeName }}</GridItem>
          <GridItem>序列号</GridItem>
          <GridItem>{{ info.devSn }}</GridItem>
          <GridItem>固件版本</GridItem>
          <GridItem>{{ info.firmwareVersion }}</GridItem>
        </Grid>
      </TabPane>
      <TabPane label="联网信息" name="name2">
        <Grid :col="2" padding="10px">
          <GridItem>入网类型</GridItem>
          <GridItem>{{ returnType(info.netType) }}</GridItem>
          <GridItem>在线状态</GridItem>
          <GridItem>{{ info.isOnline ? "在线" : "离线" }}</GridItem>
          <GridItem>最后在线时间</GridItem>
          <GridItem>{{ info.lastOnlineTimeStr }}</GridItem>
        </Grid>
      </TabPane>
      <TabPane label="会员管理" name="name3">
        <Grid :col="2" padding="10px">
          <GridItem> 添加时间</GridItem>
          <GridItem>{{ info.createDate }}</GridItem>
          <GridItem>绑定时间</GridItem>
          <GridItem>{{ info.bindTime || "无" }}</GridItem>
          <GridItem style="height: 52px; line-height: 32px">有效期</GridItem>
          <GridItem v-if="info.eDate"
            ><DatePicker
              type="date"
              :placeholder="
                info.eDate === '0000-00-00' ? '无限期' : '请选择时间'
              "
              :model-value="info.eDate !== '0000-00-00' ? info.eDate : ''"
              style="width: 200px"
          /></GridItem>
          <GridItem style="height: 52px; line-height: 32px">年费</GridItem>
          <GridItem
            ><Input v-model="info.devAnnualFee" style="width: 150px"> </Input
          ></GridItem>
          <GridItem style="height: 52px; line-height: 32px">短信费率</GridItem>
          <GridItem
            ><Input v-model="info.smsRate" style="width: 150px"> </Input
          ></GridItem>
          <GridItem style="height: 52px; line-height: 32px">电话费率</GridItem>
          <GridItem
            ><Input v-model="info.phoneRate" style="width: 150px"> </Input
          ></GridItem>
        </Grid>
        <Button type="primary" @click="saveDeviceinfo" style="margin-top: 10px"
          >保存</Button
        >
        <Share :devId="devId"></Share>
      </TabPane>
      <TabPane label="开关记录" name="name7"
        ><NodeList :nodeData="tmpData"></NodeList
      ></TabPane>
      <TabPane label="开关量记录" name="name8"
        ><NodeList :nodeData="node1Data"></NodeList
      ></TabPane>
      <TabPane v-if="info.ocFlag" label="报警信息" name="name5">
        <Warning :devSn="devSn"></Warning>
      </TabPane>
      <TabPane v-if="info.ocFlag" label="播报信息" name="name6">
        <Broadcast
          :devSn="devSn"
          :devId="devId"
          :battery="battery"
          :hornData="hornData"
        ></Broadcast>
      </TabPane>
      <TabPane v-else label="设备资源" name="name4">
        <Resources :node0Data="node0Data" :node1Data="node1Data"></Resources>
      </TabPane>
    </Tabs>
    <Button
      style="position: absolute; top: 14px; right: 16px"
      type="info"
      @click="backList"
      >返回</Button
    >
  </div>
</template>

<script>
import axios from "axios";
import { baseURL } from "@/utils/util";
import { getdeviceinfobydevsn, mbmodifydeviceinfo } from "@/api/login";
import Share from "@/components/list/components/Share.vue";
import Warning from "@/components/list/components/Warning.vue";
import Broadcast from "@/components/list/components/Broadcast.vue";
import Resources from "@/components/list/components/Resources.vue";
import NodeList from "@/components/list/components/NodeList.vue";
export default {
  components: {
    Share,
    Warning,
    Broadcast,
    Resources,
    NodeList,
  },
  props: {
    devSn: {
      type: String,
      default: "0",
    },
  },
  data() {
    return {
      devId: 0,
      battery: {},
      info: {
        eDate: "0000-00-00",
      },
      node0Data: [],
      node1Data: [],
      hornData: [],
      tmpData: [],
    };
  },
  mounted() {
    setTimeout(() => {
      this.getData();
    }, 100);
  },
  methods: {
    async getNode(devId) {
      const params = { devId };
      const {
        data: { info },
      } = await axios.get(`${baseURL}mbgetnodesbydevid`, {
        params,
      });
      if (info) {
        this.node0Data = info.filter((item) => item.nodeType === 0);
        this.node1Data = info.filter((item) => item.nodeType === 1);
        this.hornData = info.filter((item) => item.nodeType === 5);
        this.tmpData = this.hornData !== 0 ? this.hornData : this.node0Data;
      }
    },
    async saveDeviceinfo() {
      const { id, eDate, devAnnualFee, smsRate, phoneRate } = this.info;
      const params = {
        id,
        eDate,
        devAnnualFee,
        smsRate,
        phoneRate,
      };
      const { data: status } = mbmodifydeviceinfo(params);
      if (status) {
        this.$Message.info("修改成功");
      }
    },
    backList() {
      this.$emit("changeBack", true);
    },
    async getData() {
      const { info } = await getdeviceinfobydevsn({
        devSn: this.devSn,
      });
      const { id, batteryPower, batteryState, tpsState } = info;
      this.getNode(id);
      this.devId = id;
      this.battery = {
        isPowerSupply: batteryState > 0 ? "支持" : "不支持",
        batteryPower,
        batteryState,
        tpsState,
      };
      this.info = info;
      this.info.devAnnualFee = info.devAnnualFee / 100;
    },
    returnType(type) {
      if (type === 0) {
        return "有线以太网";
      }
      if (type === 1) {
        return "GPRS 2G";
      }
      if (type === 2) {
        return "GPRS 4G";
      }
      if (type === 3) {
        return "WIFI";
      }
      if (type === 4) {
        return "NBIOT";
      }
      if (type === "F") {
        return "未知类型";
      }
    },
  },
};
</script>
