<template>
<div class="i-layout-sider-logo">
  <a href="/" target="_self" class="i-link i-link-color">
    <img src="@/assets/nirenLogo.png" />
  </a>
</div>
<Menus />
</template>

<script>
import Menus from '@/components/permission/Menus.vue'
export default {
  name: 'SiderLayout',
  components: {
    Menus
  }
}
</script>
