<template>
  <div>
    <Card style="margin: 20px">
      <Searchbar
        :startTime="startTime"
        :endTime="endTime"
        :options="options"
        :select="select"
        :isKeyword="false"
        @searchBtn="searchBtn"
      ></Searchbar>
      <Table :columns="columns" :data="tabData" style="margin-top: 16px">
      </Table>
      <div class="ivu-mt ivu-text-center">
        <Page
          :total="totalInfoNum"
          :page-size="pageInfoNum"
          show-elevator
          show-total
          show-sizer
          @on-change="changePage"
          @on-page-size-change="changePageSize"
        />
      </div>
    </Card>
  </div>
</template>

<script>
import axios from "axios";
import Searchbar from "@/components/list/components/Searchbar.vue";
import { baseURL, dateFormat } from "@/utils/util";
export default {
  components: {
    Searchbar,
  },
  props: {
    nodeData: {
      type: Array,
      default: [],
    },
  },
  data() {
    return {
      options: [
        {
          value: "userName",
          label: "用户名",
        },
        {
          value: "phone",
          label: "手机号",
        },
      ],
      columns: [
        {
          title: "节点名称",
          key: "nodeName",
        },
        {
          title: "状态",
          key: "devVal",
          render: (h, params) => {
            return h("div", params.row.devVal == 1 ? "开启" : "关闭");
          },
        },
        {
          title: "时间",
          key: "createDate",
        },
      ],
      tabData: [],
      currPageNum: 1,
      totalInfoNum: 1,
      pageInfoNum: 10,
      startTime: "2000-09-01 00:00:00",
      endTime: dateFormat(new Date()),
      select: "",
      options: [],
    };
  },
  mounted() {
    setTimeout(() => {
      if (this.nodeData.length !== 0) {
        this.select = this.nodeData[0].id;
        this.getData();
        this.nodeData.map((item) => {
          this.options.push({
            value: item.id,
            label: item.nodeName,
          });
        });
      }
    }, 1000);
  },
  methods: {
    searchBtn(e) {
      const { startTime, endTime, select } = e;
      this.startTime = startTime;
      this.endTime = endTime;
      this.select = select;
      this.getData();
    },
    changePageSize(e) {
      this.pageInfoNum = e;
      this.getData();
    },
    changePage(e) {
      this.currPageNum = e;
      this.getData();
    },
    async getData() {
      const { pageInfoNum, currPageNum, startTime, endTime, select } = this;
      let params = {
        sessionId: 1,
        nodeId: select,
        pageLength: pageInfoNum,
        currentPage: currPageNum,
        fromDateTime: startTime,
        toDateTime: endTime,
      };
      const {
        data: { info },
      } = await axios.get(`${baseURL}getnodehistorydata`, {
        params,
      });
      this.totalInfoNum = info.totalRecordNum;
      this.tabData = info.zhdInfo || [];
    },
  },
};
</script>
