<template>
  <div style="padding: 5px 20px">
    <Card style="margin-top: 10px">
      <template #title>1. 输入手机号</template>
      <Input
        v-model="phone"
        placeholder="输入手机号"
        style="width: 300px; margin-right: 10px"
      />
      <Button type="primary" @click="phoneBtn" style="margin-right: 10px"
        >确定</Button
      >
    </Card>
    <Card style="margin-top: 10px">
      <template #title>2.双击选中型号</template>
      <Table
        border
        height="300"
        :columns="modelColumns"
        :data="modelData"
        @on-row-click="getUid"
        highlight-row
      ></Table>
    </Card>
    <Card style="margin-top: 10px">
      <template #title>3.双击选中UID</template>
      <Table
        border
        height="200"
        :columns="uidColumns"
        :data="uidData"
        @on-row-click="saveOldUid"
        highlight-row
      ></Table>
    </Card>
    <Card style="margin-top: 10px">
      <template #title>4.输入新的UID</template>
      <Input
        v-model="newUid"
        placeholder="输入新的UID"
        style="width: 300px; margin-right: 10px"
      />
      <Button type="primary" @click="upDataBtn" style="margin-right: 10px">确定</Button>
      <span style="margin-right: 10px">已选择用户ID：{{ this.userId }}</span>
      <span style="margin-right: 10px">已选择旧UID：{{ this.oldUid }}</span>
      <span style="margin-right: 10px">已选择新UID：{{ this.newUid }}</span>
    </Card>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      phone: "",
      modelColumns: [
        {
          title: "型号",
          key: "devTypeName",
        },
      ],
      uidColumns: [
        {
          title: "UID",
          key: "uid",
        },
      ],
      modelData: [],
      uidData: [],
      oldUid: "",
      newUid: "",
      userId: "",
    };
  },
  mounted() {
    this.getdevicetype();
  },
  methods: {
    async upDataBtn() {
      const params = {
        oldUid: this.oldUid,
        newUid: this.newUid,
        userId: this.userId,
      };
      const {
        data: { msg },
      } = await axios.get(
        "https://123.nireniot.com/api/modifydeviceuidanduserid",
        {
          params,
        }
      );
      this.$Message.info(msg);
    },
    saveOldUid(e) {
      this.oldUid = e.uid;
    },
    async getUid(e) {
      this.uidData = [];
      const params = { devType: e.devTypeName };
      const {
        data: { info },
      } = await axios.get(
        "https://123.nireniot.com/api/getuidlistbydevicetype",
        {
          params,
        }
      );
      info.forEach((item) => {
        this.uidData.push({
          uid: item,
        });
      });
    },
    async getdevicetype() {
      const {
        data: { info },
      } = await axios.get("https://123.nireniot.com/api/getdevicetype");
      info.forEach((item) => {
        this.modelData.push({
          devTypeName: item,
        });
      });
    },
    async phoneBtn() {
      const params = { phone: this.phone };
      const {
        data: { info, status },
      } = await axios.get("https://123.nireniot.com/api/getuseridbyphone", {
        params,
      });
      this.userId = info.id;
      if (status === 200) {
        this.$Message.info("已选择用户ID");
      }
    },
  },
};
</script>

<style>
</style>