<template>
  <div style="padding: 5px 20px">
    <Card>
      <template #title> <Title :level="3">数据对比</Title> </template>
      <Row :gutter="10">
        <i-col>设备总数：{{ statistical.devTotalNum }}</i-col>
        <i-col>在线设备总数：{{ statistical.devOnlineTotalNum }}</i-col>
        <i-col>离线设备总数：{{ statistical.devOfflineTotalNum }}</i-col>
        <i-col>已绑定设备总数：{{ statistical.devBindTotalNum }}</i-col>
        <i-col
          >已绑定在线设备总数：{{ statistical.devBindOnlineTotalNum }}</i-col
        >
      </Row>
    </Card>
    <Row :gutter="10" style="margin-top: 10px">
      <i-col :lg="12">
        <Card>
          <template #title> <Title :level="4">型号总表</Title> </template>
          <Table
            border
            height="300"
            :columns="modelColumns"
            :data="modelData"
          ></Table>
        </Card>
      </i-col>
      <i-col :lg="12">
        <Card>
          <template #title> <Title :level="4">会员总表</Title> </template>
          <UserChart></UserChart>
        </Card>
      </i-col>
    </Row>
    <Row :gutter="10" style="margin-top: 10px">
      <i-col :lg="8">
        <Card>
          <template #title>
            <Title :level="5">微信充值统计（元）</Title>
          </template>
          <template #extra>
            <Select v-model="wxModel" @on-change="changWx">
              <Option
                v-for="item in default"
                :value="item.value"
                :key="item.value"
                >{{ item.label }}</Option
              >
            </Select>
          </template>
          <WxChart :wxModel="wxModel" :wxData="wxData"></WxChart>
        </Card>
      </i-col>
      <i-col :lg="8">
        <Card>
          <template #title>
            <Title :level="5">设备续费统计</Title>
          </template>
          <template #extra>
            <Select v-model="renewalModel" @on-change="changRenewal">
              <Option
                v-for="item in default"
                :value="item.value"
                :key="item.value"
                >{{ item.label }}</Option
              >
            </Select>
          </template>
          <RenewalChart
            :renewalModel="renewalModel"
            :renewalData="renewalData"
          ></RenewalChart>
        </Card>
      </i-col>
      <i-col :lg="8">
        <Card>
          <template #title> <Title :level="5">流量使用情况</Title> </template>
          <div class="tmp-flex">
            <div class="ivu-text-center">
              <div class="tmp-flex1">
                <Icon
                  type="ios-mail-outline"
                  size="30"
                  style="margin-right: 5px"
                />短信发送成功
              </div>
              <div class="tmp-font">{{ callSmsNum.smsSendSucceedNum }}</div>
            </div>
            <div class="ivu-text-center">
              <div class="tmp-flex1">
                <Icon
                  type="ios-mail-outline"
                  size="30"
                  style="margin-right: 5px"
                />短信发送失败
              </div>
              <div class="tmp-font">
                {{ callSmsNum.smsTotalNum - callSmsNum.smsSendSucceedNum }}
              </div>
            </div>
            <div class="ivu-text-center">
              <div class="tmp-flex1">
                <Icon
                  type="ios-call-outline"
                  size="30"
                  style="margin-right: 5px"
                />电话拨打成功
              </div>
              <div class="tmp-font">{{ callSmsNum.phoneSendSucceedNum }}</div>
            </div>
            <div class="ivu-text-center">
              <div class="tmp-flex1">
                <Icon
                  type="ios-call-outline"
                  size="30"
                  style="margin-right: 5px"
                />电话拨打失败
              </div>
              <div class="tmp-font">
                {{ callSmsNum.phoneTotalNum - callSmsNum.phoneSendSucceedNum }}
              </div>
            </div>
          </div>
        </Card>
        <Row :gutter="10" style="margin-top: 10px">
          <i-col :lg="12">
            <Card>
              <template #title> <Title :level="5">用户转化</Title> </template>

              <NumberInfo
                sub-title="新增用户"
                :sub-total="conversion.conversionRateStr"
                :status="conversion.conversionRateStr > 0 ? 'up' : 'down'"
              >
                <template #total>
                  <Numeral
                    :value="conversion.newUserNum"
                    format="0,0"
                    style="font-size: 45px"
                  />
                </template>
              </NumberInfo>
              <Divider style="margin: 8px 0px"></Divider>
              <Row>
                <i-col :lg="12"> 总访问量 </i-col>
                <i-col :lg="12" style="text-align: right">
                  <Numeral :value="conversion.dTotalBalance" format="0,0.00">
                    <template #prefix>
                      <strong>¥</strong>
                    </template>
                  </Numeral>
                </i-col>
              </Row>
            </Card>
          </i-col>
          <i-col :lg="12">
            <Card>
              <template #title>
                <Title :level="5">设备信号占比</Title>
              </template>
              <SignalChart></SignalChart>
            </Card>
          </i-col>
        </Row>
      </i-col>
    </Row>
  </div>
</template>

<script>
import axios from "axios";
import { baseURL } from "@/utils/util";
import {
  getDeviceNum,
  getAllDevice,
  getCallSmsNum,
  getConversion,
} from "@/api/login";
import UserChart from "@/components/chart-g2/user-chart.vue";
import WxChart from "@/components/chart-g2/wx-chart.vue";
import RenewalChart from "@/components/chart-g2/renewal-chart.vue";
import SignalChart from "@/components/chart-g2/signal-chart.vue";
export default {
  components: {
    UserChart,
    WxChart,
    RenewalChart,
    SignalChart,
  },
  data() {
    return {
      modelColumns: [
        {
          title: "型号",
          key: "devTypeName",
        },
        {
          title: "设备总数",
          key: "totalNum",
          sortable: true,
        },
        {
          title: "已绑定设备",
          key: "bindNum",
          sortable: true,
        },
        {
          title: "在线设备",
          key: "onlineNum",
          sortable: true,
        },
        {
          title: "已绑定在线设备",
          key: "bindOnlineNum",
          sortable: true,
        },
      ],
      modelData: [],
      statistical: {},
      wxData: [],
      wxModel: 0,
      renewalData: [],
      renewalModel: 0,
      default: [
        {
          value: 0,
          label: "日",
        },
        {
          value: 1,
          label: "月",
        },
        {
          value: 2,
          label: "年",
        },
      ],
      callSmsNum: {
        phoneSendSucceedNum: 0,
        phoneTotalNum: 0,
        smsSendSucceedNum: 0,
        smsTotalNum: 0,
      },
      conversion: {},
    };
  },
  mounted() {
    this.getDeviceNum();
    this.getAllDevice();
    this.getWx();
    this.getRenewal();
    this.getCallSmsNum();
    this.getConversion();
  },
  methods: {
    async getDeviceNum() {
      const { info } = await getDeviceNum();
      this.statistical = info;
    },
    async getAllDevice() {
      const { info } = await getAllDevice();
      this.modelData = info;
    },
    async getWx() {
      const params = { dateFlag: this.wxModel };
      const {
        data: { info },
      } = await axios.get(`${baseURL}getwxtopupstatistics`, {
        params,
      });
      this.wxData = info;
    },
    async getRenewal() {
      const params = { dateFlag: this.renewalModel };
      const {
        data: { info },
      } = await axios.get(`${baseURL}getdevicerenewstatistics`, {
        params,
      });
      this.renewalData = info;
    },
    changRenewal(e) {
      this.renewalModel = e;
      this, this.getRenewal();
    },
    changWx(e) {
      this.wxModel = e;
      this, this.getWx();
    },
    async getCallSmsNum() {
      const { info } = await getCallSmsNum();
      this.callSmsNum = info;
    },
    async getConversion() {
      const { info } = await getConversion();
      this.conversion = info;
    },
  },
};
</script>
<style lang="less" scoped>
h3.ivu-typography,
h4.ivu-typography {
  margin-bottom: 0;
  line-height: 1;
}
h5.ivu-typography {
  margin-bottom: 0;
}
.tmp-flex {
  display: flex;
  justify-content: space-around;
}
.tmp-flex1 {
  display: flex;
  align-items: center;
}
.tmp-font {
  font-size: 25px;
  font-weight: 600;
}
/deep/ .ivu-number-info-total {
  height: 66px;
  line-height: 66px;
}
</style>

