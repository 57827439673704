<template>
  <div>
    <Row :gutter="20" style="min-height: 133px">
      <i-col :lg="24" id="signal"></i-col>
    </Row>
  </div>
</template>

<script>
import { getSignal } from "@/api/login";
import { Chart } from "@antv/g2";

export default {
  name: "renewal--chart",
  setup() {},
  created() {},
  mounted() {
    setTimeout(() => {
      this.init();
    }, 10);
  },
  methods: {
    async init() {
      const { info } = await getSignal();
      const total = info[0].devNum + info[1].devNum;
      info[0].percent = (info[0].devNum / total).toFixed(2);
      info[1].percent = (info[1].devNum / total).toFixed(2);
      const data = [
        {
          item: 'LAN',
          count: info[0].devNum,
          percent: Number(info[0].percent),
        },
        {
          item: '4G',
          count: info[1].devNum,
          percent: Number(info[1].percent),
        },
      ];

      const chart = new Chart({
        container: "signal",
        autoFit: true,
        height: 500,
      });

      chart.data(data);

      chart.coordinate("theta", {
        radius: 1,
      });

      chart.scale("percent", {
        formatter: (val) => {
          val = val * 100 + "%";
          return val;
        },
      });
      chart.tooltip({
        showTitle: false,
        showMarkers: false,
      });

      chart.legend("item", {
        position: "bottom", // 配置图例显示位置
        custom: true, // 关键字段，告诉 G2，要使用自定义的图例
        items: data.map((obj, index) => {
          return {
            name: obj.item, // 对应 itemName
            value: obj.percent, // 对应 itemValue
            marker: {
              symbol: "square", // marker 的形状
              style: {
                r: 5, // marker 图形半径
                fill: chart.getTheme().colors10[index], // marker 颜色，使用默认颜色，同图形对应
              },
            }, // marker 配置
          };
        }),
        itemValue: {
          style: {
            fill: "#999",
          }, // 配置 itemValue 样式
          formatter: (val) => `${val * 100}%`, // 格式化 itemValue 内容
        },
      });
      chart.axis(false); // 关闭坐标轴
      const interval = chart
        .interval()
        .adjust("stack")
        .position("percent")
        .color("item")
        .label("percent", {
          offset: -20,
          style: {
            textAlign: "center",
            shadowBlur: 2,
            shadowColor: "rgba(0, 0, 0, .45)",
            fill: "#fff",
          },
        })
        .tooltip("item*percent", (item, percent) => {
          percent = percent * 100 + "%";
          return {
            name: item,
            value: percent,
          };
        })
        .style({
          lineWidth: 1,
          stroke: "#fff",
        });
      chart.interaction("element-single-selected");
      chart.render();

      // 默认选择
      interval.elements[0].setState("selected", true);
    },
  },
};
</script>
