<template>
  <div>
    <PageHeader hidden-breadcrumb>
      <template #title><div class="demo-title">用户列表</div></template>
    </PageHeader>
    <Card style="margin: 20px" v-if="isList">
      <Searchbar
        :startTime="startTime"
        :endTime="endTime"
        :options="options"
        @searchBtn="searchBtn"
      ></Searchbar>
      <Table :columns="columns" :data="tabData" style="margin-top: 16px">
        <template #action="{ row, index }">
          <Button type="primary" size="small" @click="showInfo(index)"
            >详情</Button
          >
        </template>
      </Table>
      <div class="ivu-mt ivu-text-center">
        <Page
          :total="totalInfoNum"
          :page-size="pageInfoNum"
          show-elevator
          show-total
          show-sizer
          :model-value="currPageNum"
          @on-change="changePage"
          @on-page-size-change="changePageSize"
        />
      </div>
    </Card>
    <Card style="margin: 20px" v-else>
      <Tabs value="name1" @on-click="handleTabsClick">
        <TabPane label="会员信息" name="name4">
          <Grid :col="2" padding="10px">
            <GridItem style="height: 76px; line-height: 56px">头像</GridItem>
            <GridItem
              ><img style="width: 50px; height: 50px" :src="info.headImg"
            /></GridItem>
            <GridItem>昵称</GridItem>
            <GridItem>{{ info.userName }}</GridItem>
            <GridItem>手机号</GridItem>
            <GridItem>{{ info.phone || "无" }}</GridItem>
            <GridItem>余额</GridItem>
            <GridItem>{{ info.balance }}</GridItem>
            <GridItem>注册时间</GridItem>
            <GridItem>{{ info.createDate }}</GridItem>
            <GridItem>最近登陆时间</GridItem>
            <GridItem>{{ info.lastLoginTime || "无" }}</GridItem>
            <GridItem>设备总数</GridItem>
            <GridItem>{{ info.devNum }}</GridItem>
            <GridItem>分享设备总数</GridItem>
            <GridItem>{{ info.shareDevName }}</GridItem>
            <GridItem>接受分享设备总数 </GridItem>
            <GridItem>{{ info.getDevNum }}</GridItem>
            <GridItem>传感器总数 </GridItem>
            <GridItem>{{ info.sensorNum }}</GridItem>
          </Grid>
        </TabPane>
        <TabPane label="设备列表" name="isDeviceList">
          <Card title="设备列表" style="margin: 20px">
            <Table
              v-if="isDeviceList"
              border
              ref="selection"
              :columns="deviceColumns"
              :data="deviceData"
            >
              <template #action="{ row, index }">
                <Button
                  type="primary"
                  size="small"
                  @click="showDevice(index, 'isDeviceList', 'deviceData')"
                  >详情</Button
                >
              </template>
            </Table>
            <DeviceInfo v-else :devSn="devSn" @changeBack="isBack"></DeviceInfo>
          </Card>
        </TabPane>
        <TabPane label="分享设备列表" name="isShareList">
          <Card title="分享设备列表" style="margin: 20px">
            <Table
              v-if="isShareList"
              border
              ref="selection"
              :columns="deviceColumns"
              :data="shareData"
            >
              <template #action="{ row, index }">
                <Button
                  type="primary"
                  size="small"
                  @click="showDevice(index, 'isShareList', 'shareData')"
                  >详情</Button
                >
              </template>
            </Table>
            <DeviceInfo v-else :devSn="devSn" @changeBack="isBack"></DeviceInfo>
          </Card>
        </TabPane>
        <TabPane label="被分享设备列表" name="isGetList">
          <Card title="分享设备列表" style="margin: 20px">
            <Table
              v-if="isGetList"
              border
              ref="selection"
              :columns="deviceColumns"
              :data="getDataList"
            >
              <template #action="{ row, index }">
                <Button
                  type="primary"
                  size="small"
                  @click="showDevice(index, 'isGetList', 'getDataList')"
                  >详情</Button
                >
              </template>
            </Table>
            <DeviceInfo
              v-else
              :devSn="devSn"
              @changeBack="isBack"
            ></DeviceInfo> </Card
        ></TabPane>
      </Tabs>
      <Button
        style="position: absolute; top: 14px; right: 16px"
        type="info"
        @click="changeIsBack"
        >返回</Button
      >
    </Card>
  </div>
</template>

<script>
import axios from "axios";
import DeviceInfo from "@/components/list/components/DeviceInfo.vue";
import Searchbar from "@/components/list/components/Searchbar.vue";
import { baseURL, dateFormat } from "@/utils/util";
export default {
  components: {
    DeviceInfo,
    Searchbar,
  },
  data() {
    return {
      options: [
        {
          value: "userName",
          label: "用户名",
        },
        {
          value: "phone",
          label: "手机号",
        },
      ],
      devSn: "",
      isDeviceList: true,
      isShareList: true,
      isGetList: true,
      columns: [
        {
          title: "头像",
          key: "headImg",
          render: (h, params) => {
            return h(
              "img",
              {
                src: params.row.headImg,
                style: {
                  width: "48px",
                  height: "48px",
                },
              },
              params.row.headImg
            );
          },
        },
        {
          title: "昵称",
          key: "userName",
        },
        {
          title: "手机号",
          key: "phone",
        },
        {
          title: "设备数",
          key: "devNum",
        },
        {
          title: "分享设备数",
          key: "shareDevName",
        },
        {
          title: "被分享设备数",
          key: "getDevNum",
        },
        {
          title: "传感器数",
          key: "sensorNum",
        },
        {
          title: "注册时间",
          key: "createDateStr",
          width: 200,
        },
        {
          title: "最近登录时间",
          key: "lastLoginTime",
          width: 200,
        },
        {
          title: "金额",
          key: "balance",
        },
        {
          title: "操作",
          slot: "action",
          width: 150,
          align: "center",
        },
      ],
      tabData: [],
      currPageNum: 1,
      totalInfoNum: 1,
      pageInfoNum: 10,
      startTime: "2000-09-01 00:00:00",
      endTime: dateFormat(new Date()),
      select: "",
      keyword: "",
      isList: true,
      info: {},
      deviceColumns: [
        {
          type: "selection",
          width: 60,
          align: "center",
        },
        {
          title: "设备型号",
          key: "devTypeName",
          width: 150,
        },
        {
          title: "序列号",
          key: "devSn",
          width: 250,
        },
        {
          title: "所属会员",
          key: "userNames",
          tooltip: true,
        },
        {
          title: "入网类型",
          key: "netType",
          width: 110,
          render: (h, params) => {
            return h("div", this.returnType(params.row.netType));
          },
        },
        {
          title: "信号强度",
          key: "sign",
        },
        {
          title: "在线状态",
          key: "isOnline",
          render: (h, params) => {
            return h("div", params.row.isOnline ? "在线" : "离线");
          },
        },

        {
          title: "年费",
          key: "devAnnualFee",
          render: (h, params) => {
            return h("div", [params.row.devAnnualFee / 100]);
          },
        },
        {
          title: "短信费率",
          key: "smsRate",
        },
        {
          title: "电话费率",
          key: "phoneRate",
        },
        {
          title: "最后在线时间",
          key: "lastOnlineTimeStr",
          width: 200,
        },
        {
          title: "操作",
          slot: "action",
          width: 150,
          align: "center",
        },
      ],
      deviceData: [],
      shareData: [],
      getDataList: [],
      currentType: "",
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    handleTabsClick(e) {
      this.currentType = e;
    },
    changeIsBack() {
      this.isList = true;
    },
    searchBtn(e) {
      const { startTime, endTime, keyword, select } = e;
      this.startTime = startTime;
      this.endTime = endTime;
      this.keyword = keyword;
      this.select = select;
      this.getData();
    },
    isBack() {
      this[this.currentType] = true;
    },
    returnType(type) {
      if (type === 0) {
        return "有线以太网";
      }
      if (type === 1) {
        return "GPRS 2G";
      }
      if (type === 2) {
        return "GPRS 4G";
      }
      if (type === 3) {
        return "WIFI";
      }
      if (type === 4) {
        return "NBIOT";
      }
      if (type === "F") {
        return "未知类型";
      }
    },
    async getList1(userId) {
      const params = { userId };
      const {
        data: { info },
      } = await axios.get(`${baseURL}mbgetdevinfosbyuserid`, {
        params,
      });
      this.deviceData = info || [];
    },
    async getList2(userId) {
      const params = { userId };
      const {
        data: { info },
      } = await axios.get(`${baseURL}mbgetsharedevicelistbyuserid`, {
        params,
      });
      this.shareData = info || [];
    },
    async getList3(userId) {
      const params = { userId };
      const {
        data: { info },
      } = await axios.get(`${baseURL}mbgetpassivesharedeviceinfolistbyuserid`, {
        params,
      });
      this.getDataList = info || [];
    },
    showInfo(index) {
      this.getList1(this.tabData[index].id);
      this.getList2(this.tabData[index].id);
      this.getList3(this.tabData[index].id);
      this.info = this.tabData[index];
      this.isList = false;
    },
    showDevice(index, type, list) {
      this.devSn = this[list][index].devSn;
      this[type] = false;
      this.currentType = type;
    },
    changePageSize(e) {
      this.pageInfoNum = e;
      this.getData();
    },
    changePage(e) {
      this.currPageNum = e;
      this.getData();
    },
    async getData() {
      const { pageInfoNum, currPageNum, startTime, endTime, select, keyword } =
        this;
      let params = {
        pageInfoNum,
        currPageNum,
        startTime,
        endTime,
      };
      if (select !== undefined && keyword !== "") {
        params[`${select}`] = keyword;
      }
      const {
        data: {
          info: { objInfo, totalInfoNum },
        },
      } = await axios.get(`${baseURL}getsystemallusers`, {
        params,
      });
      this.totalInfoNum = totalInfoNum;
      this.tabData = objInfo;
    },
  },
};
</script>
