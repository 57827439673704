export function timeFix() {
  const time = new Date()
  const hour = time.getHours()
  return hour < 9 ? '早上好' : hour <= 11 ? '上午好' : hour <= 13 ? '中午好' : hour < 20 ? '下午好' : '晚上好'
}

export function welcome() {
  const arr = ['休息一会儿吧', '准备吃什么呢?', '要不要打一把游戏', '我猜你可能累了']
  const index = Math.floor(Math.random() * arr.length)
  return arr[index]
}

// 将秒转为时分秒 00:00:00
export function getFormatDuringTime(during) {
  var secondTime = parseInt(during); // 秒
  var minuteTime = 0; // 分
  var hourTime = 0; // 小时
  if (secondTime >= 60) {
    minuteTime = parseInt(secondTime / 60);
    secondTime = parseInt(secondTime % 60);
    if (minuteTime >= 60) {
      hourTime = parseInt(minuteTime / 60);
      minuteTime = parseInt(minuteTime % 60);
    }
  }
  var result = "" + (parseInt(secondTime) < 10 ? "0" + parseInt(secondTime) : parseInt(secondTime));
  result = "" + (parseInt(minuteTime) < 10 ? "0" + parseInt(minuteTime) : parseInt(minuteTime)) + ":" + result;
  result = "" + (parseInt(hourTime) < 10 ? "0" + parseInt(hourTime) : parseInt(hourTime)) + ":" + result;
  return result;
}

export function dateFormat(dateData) {
  const date = new Date(dateData);
  const y = date.getFullYear();
  let m = date.getMonth() + 1;
  m = m < 10 ? `0${m}` : m;
  let d = date.getDate();
  d = d < 10 ? `0${d}` : d;
  let h = date.getHours();
  h = h < 10 ? `0${h}` : h;
  let min = date.getMinutes();
  min = min < 10 ? `0${min}` : min;
  let s = date.getSeconds();
  s = s < 10 ? `0${s}` : s;
  const time = `${y}-${m}-${d} ${h}:${min}:${s}`;
  return time;
};

export const baseURL = 'https://test.nireniot.com/api/';