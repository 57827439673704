<template>       
<Breadcrumb/>
<div class="i-layout-header-right">
    <Dropdown class="i-layout-header-trigger i-layout-header-trigger-min" transfer @on-click="userBtn">
        <span>
        <span class="ivu-avatar ivu-avatar-circle ivu-avatar-image ivu-avatar-small">
            <img src="@/assets/logo.png"/>
        </span>
        <span class="i-layout-header-user-name">{{userInfo.userName}}</span>
        </span>
        <template #list>
            <DropdownMenu>
                <DropdownItem name='logout'><Icon type="ios-exit-outline" size="15"/> 退出登录</DropdownItem>
            </DropdownMenu>
        </template>
    </Dropdown>
</div>
</template>

<script>
import Breadcrumb from '@/components/permission/Breadcrumb.vue'
export default {
  name: 'HeaderLayout',
  components: {
    Breadcrumb
  },
  data () {
      return {
        userInfo:{}
      }
  },
  created(){
    if(sessionStorage.userInfo){
        this.userInfo = JSON.parse(sessionStorage.userInfo)
    }
  },
    methods: {
        collapsedSider(){
            this.$emit('collapsedSider')
        },
        userBtn(name){
            if(name === 'logout'){
                this.$store.commit('setToken', '')
                this.$router.push({path:'/login'})
            }
        },
    }
}
</script>